.medical_survey_question_card__container {
	margin-bottom: 10px;
}

.medical_survey_question_card__main_container {
}

.medical_survey_question_card__info {
	font-size: 14px;
    margin-bottom: 0px;
}

.medical_survey_question_card__answer {
	font-size: 14px;
	font-family: 'GintoNormal-Black';
	margin-bottom: 0px;
}

.medical_survey_question_card__user_allergy {
	color: $primary-blue;
	font-size: 14px;
	font-family: 'GintoNormal-Black';
	margin-bottom: 0px;
}

.medical_survey_question_card__user_medication {
	color: $primary-blue;
	font-size: 14px;
	font-family: 'GintoNormal-Black';
	margin-bottom: 0px;
}