.patient_profile__title_label {
	font-family: 'GintoNormal-Medium';
	font-size: 26px;
	text-align: center;
}

.patient_profile__send_button {
	width: 100%;
}

.patient_profile__avatar_container {
	background-color: black;
	max-width: 100%;
    height: 200px;
    position: relative;
    overflow: hidden;

	  display: table-cell;
	  vertical-align: middle;
	  border: 3px solid $primary-dark-gray;
	  min-width: 146px;
}

.patient_profile__avatar_container_text {
	background-color: black;
	color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.patient_profile__avatar_image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.patient_profile__send_button_container {
	margin-bottom: 10px;
}

.patient_profile__user_info {
	font-size: 14px;
	margin-bottom: 4px;
}

.patient_profile__user_info_label {
	font-family: 'GintoNormal-Medium';
	font-size: 14px;
	margin-bottom: 4px;
}

.patient_profile__bottom_container {
	padding-top: 24px;
	overflow-y: scroll;
}

.patient_profile__user_name_label {
	font-family: 'GintoNormal-Medium';
	font-size: 22px;
}

.patient_profile__button {
	background-color: $primary-medium-gray;
	border: 1px solid $primary-dark-gray;
	box-shadow: none;
	border-radius: 15px;
	font-size: 14px; 
	font-family: 'DomaineText-Medium';
	font-weight: 600;
	height: 32px;
	width: 100%;
	max-width: 320px;
	letter-spacing: 0.5px;
	margin-top: 12px;
	outline: none !important;
    outline-offset: none !important;
}

.patient_profile__button:hover {
	background-color: $primary-dark-gray;
	border: 1px solid $primary-dark-gray;
	color: $primary-pink;
	box-shadow: none;
	border-radius: 15px;
	font-size: 14px; 
	font-family: 'DomaineText-Medium';
	font-weight: 600;
	height: 32px;
	width: 100%;
	max-width: 320px;
	letter-spacing: 0.5px;
	margin-top: 12px;
	outline: none !important;
    outline-offset: none !important;
}

.patient_profile__header {
	font-family: 'GintoNormal-Medium';
	font-size: 20px;
	margin-bottom: 24px;
}

.patient_profile__insurance_header {
	font-family: 'GintoNormal-Medium';
	font-size: 20px;
	margin-bottom: 12px;
}

.patient_profile__medical_history_header {
	font-family: 'GintoNormal-Medium';
	font-size: 20px;
	margin-bottom: 12px;
}

.patient_profile__insurance_container {
	padding-top: 24px;
}

.patient_profile__insurance_main_container {
	height: 152px;
	overflow-y: scroll;
	border-bottom: 1px solid black;
}

.patient_profile__empty_text {
	text-align: center;
}

.patient_profile__loading_animation {
	height: 100px;
	width: 100px;
	position: absolute;
	top: 50%;
	left: 45%;
}

.patient_profile__appointment_container {
	height: 152px;
	overflow-y: scroll;
	border-bottom: 1px solid black;
	margin-top: 10px;
}