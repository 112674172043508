.schedule_generator__legend {
	// width: 140px;
	width: 100%;
	position: relative;	
}

.schedule_generator__header {
	font-family: "DomaineText-Medium";
	font-size: 28px;
	margin-top: 20px;
	margin-bottom: 97px;
	// padding-top: 4px;
}

.schedule_generator__header_form_container {
	position: relative;
	padding-right: 0px;
}

.schedule_generator__appointment_type_header_container {
	margin-bottom: 0px;
	border-top: 1px solid black;
	border-left: 1px solid black;
	// border-right: 0.5px solid black;
	padding-left: 15px;
	// height: 20.5px;
}

.schedule_generator__appointment_type_header_container:last-of-type {
	margin-bottom: 0px;
	border-top: 1px solid black;
	border-bottom: 1px solid black;
}

.schedule_generator__appointment_type_header {
	font-size: 18px;
	margin-bottom: 0px;
	padding-top: 20px;
	padding-bottom: 20.5px;
}

.schedule_generator__loading_animation {
	height: 100px;
	width: 100px;
	position: absolute;
	top: 45%;
	left: 40%;
	z-index: 1000;
}

.schedule_generator__appointment_type_header:last-of-type {
}

.schedule_generator__practice_form_container {
	position: absolute;
	left: 2px;
	top: 110px;
	width: 100%;
}

.schedule_generator__form_container {
	padding-left: 0px;
	padding-right: 0px;
}

.schedule_generator__form_save_button_container {
	padding-top: 20px;
	padding-bottom: 28px;
	text-align: center;
	border-right: 1px solid black;
}

.schedule_generator__form_op_header {
	font-size: 14px;
	// margin-bottom: 12px;
	font-family: "GintoNormal-Black";
	text-align: center;
	border-bottom: 1px solid black;
	border-right: 1px solid black;
	padding-bottom: 12px;
	margin-bottom: 0px;
	padding-top: 24px;
}

.DateInput_input {
	cursor: pointer;
	// width: 100px !important;
}

.schedule_generator__date_picker {
	// position: absolute;
	top: 16px;
	// right: -840px;
	// right: 0px;
	// left: 100px;
	z-index: 1;
	// left: calc(100% + 354px);
	left: 100%;
	// right: 0px;
	// width: 340px;
	width: 100%;
	// width: fit-content;
}

.schedule_generator__date_copy_form {
	display: inline-block;
	position: absolute;
	// bottom: 40px;
	top: 39px;
	right: 0px;
}

.schedule_generator__date_copy_text {
	display: inline-block;
	// margin-left: 24px;
}

.schedule_generator__practice_form_select {
	display: inline-block;
	font-size: 14px;
	    font-family: "GintoNormal-Black";
	    text-transform: uppercase;
	// margin-bottom: 19.5px;
	height: 30px;
	margin-left: 12px;
	position: absolute;
	right: 15px;
	top: 3px;
	text-align: center;
	// margin: 0 auto;
	border: none;
	outline: none;
}

.schedule_generator__repeat_select {
	margin: 0px 6px;
	width: 44px;
	text-align: center;
}

.schedule_generator__date_copy_button {
	font-family: "GintoNormal-Black";
	font-size: 12px;
	text-transform: uppercase;
	border-radius: 24px;
	height: 30px;
	width: 60px;
	background-color: $primary-light-gray;
	border: 1px solid black;
	margin-left: 12px;
}

.schedule_generator__date_copy_button:hover {
	font-family: "GintoNormal-Black";
	font-size: 12px;
	text-transform: uppercase;
	border-radius: 24px;
	height: 30px;
	width: 60px;
	color: $primary-pink;
	background-color: $primary-dark-gray;
	border: 1px solid black;
	margin-left: 12px;
}

// .schedule_generator__op_form_container::-webkit-scrollbar {
//   display: none;
// }

.schedule_generator__op_form_container {
	border: 1px solid black;
	width: 100%;
	overflow-y: hidden;
	min-height: 509px;
	overflow-x: scroll;
}

.schedule_generator__op_form_scroll_container {
	width: 4400px;
	// overflow-x: scroll;
	padding-bottom: 0px;
	// height: 480px;
}

.schedule_generator__date_picker_title {
	margin-left: 15px;
	display: inline-block;
	margin-bottom: 0px;
	line-height: 50px;
	margin-top: 27px;
	margin-bottom: 20px;
	position: relative;
}

.schedule_generator__form {
	width: 140px;
	display: inline-block;
}

.schedule_generator__form_select {
	display: block;
	// margin-bottom: 19.5px;
	height: 34px;
	margin: 0 auto;
	width: 40px;
    text-align: center;
	// margin-bottom: 18px;
}

.schedule_generator__form_select_container {
	padding-top: 14px;
	padding-bottom: 14px;
	border-bottom: 1px solid black;
	border-right: 1px solid black;
	height: 63px;
}

.schedule_generator__practice_header {
	// font-family: "GintoNormal-Black";
	font-size: 18px;
	// text-transform: uppercase;
	margin-top: 24px;
	display: inline-block;
	padding-left: 15px;
	// margin-bottom: 16px;
}

.schedule_generator__form_practice_update_button {
	display: inline-block;
	font-family: "GintoNormal-Black";
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 24px;
	height: 38px;
	width: 38px;
	// width: 90px;
	// width: 40px;
	margin-left: 12px;
	background-color: white;
	position: absolute;
	right: 154px;
	top: -2px;
	border: none;
	outline: none;
	right: 148px;
    top: -3.5px;
}

.schedule_generator__form_practice_update_button:hover {
	display: inline-block;
	font-family: "GintoNormal-Black";
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 24px;
	height: 38px;
	width: 38px;
	// width: 90px;
	// width: 40px;
	margin-left: 12px;
	background-color: white;
	position: absolute;
	right: 154px;
	top: -2px;
	border: none;
	outline: none;
	right: 148px;
	top: -3.5px;
}

.schedule_generator__form_practice_update_button_icon {
	max-width: 100%;
	height: 18px;
	width: 18px;
	margin: 0 auto;
}

.schedule_generator__form_save_button {
	font-family: "GintoNormal-Black";
	font-size: 12px;
	text-transform: uppercase;
	border-radius: 24px;
	height: 30px;
	width: 60px;
	background-color: $primary-light-gray;
	border: 1px solid black;
}

.schedule_generator__form_save_button:hover {
	font-family: "GintoNormal-Black";
	font-size: 12px;
	text-transform: uppercase;
	border-radius: 24px;
	height: 30px;
	width: 60px;
	color: $primary-pink;
	background-color: $primary-dark-gray;
	border: 1px solid black;
}

.schedule_generator__practice_name_container {
	position: absolute;
	right: 15px;
	top: -24px;
}

.schedule_generator__practice_name {
	font-size: 14px;
	font-family: "GintoNormal-Black";
	text-transform: uppercase;
}