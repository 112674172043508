.repair_issues_list__main_container {
	border-bottom: 1px solid lightgray;
	border-left: 0px;
	height: calc(100vh - 125px);
	padding-left: 15px;
	padding-right: 0px;
	overflow-y: scroll;
	overflow-x: hidden;
	cursor: pointer;
}

.repair_issues_list__header {
	margin-bottom: 10px;
}

.repair_issues_list__status_form_select {
	display: inline-block;
	margin-top: 20px;
	height: 28px;
	min-width: 120px;
}

.repair_issues_list__pagination_container {
	width: 100%;
	font-size: 14px;
	text-align: center;
	position: absolute;
	padding-top: 14px;
}

.repair_issues_list__pagination_right_arrow {
    height: 100%;
    max-height: 12px;
    top: 6.5px;
    cursor: pointer;
}

.repair_issues_list__pagination_left_arrow {
    height: 100%;
    max-height: 12px;
    top: 6.5px;
    cursor: pointer;
}

.repair_issues_list__pagination_left_arrow_container {
	max-width: 10px;
	display: inline-block;
}

.repair_issues_list__pagination_right_arrow_container {
	max-width: 10px;
	display: inline-block;
}

.repair_issues_list__pagination__text_container {
	display: inline-block;
	padding-left: 12px;
	padding-right: 12px;
}

.repair_issues_list__pagination_container_text {
	margin-bottom: 0px;
}

.repair_issues_list__loading_animation {
	height: 100px;
	width: 100px;
	position: absolute;
	top: 25%;
	left: 40%;
	z-index: 1000;
}

.repair_issues_list__filter_container {
	padding-left: 8px;
	border-bottom: 1px solid lightgray;
}

.repair_issues_list__title_container {
	border-bottom: 1px solid lightgray;
}


.repair_issues_list__title {
	font-size: 18px;
	font-family: "GintoNormal-Medium";
    padding-top: 24px;
    padding-bottom: 10px;
}

.repair_issues_list__status_form {
	display: inline-block;
}

.repair_issues_list__filter_title {
	display: inline-block;
	font-size: 18px;
	font-family: "GintoNormal-Medium";
    padding-top: 24px;
    margin-right: 10px;
}