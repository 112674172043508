.no_ssn_user_card__main_container {
	border-bottom: 1px solid lightgray;
	height: 34px;
}

.no_ssn_user_card__container {
}

.no_ssn_user_card__name {
	font-size: 14px;
    margin-top: 8px;
    margin-bottom: 0px;
    overflow-y: hidden;
    max-height: 20px;
}

.no_ssn_user_card__email {
	font-size: 14px;
    margin-top: 8px;
    margin-bottom: 0px;
    overflow-y: hidden;
    max-height: 20px;
}

.no_ssn_user_card__phone {
	font-size: 14px;
    margin-top: 8px;
    margin-bottom: 0px;
    overflow-y: hidden;
    max-height: 20px;
}