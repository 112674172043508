.employee_production__main_container {
	margin-top: 14px;
}

.employee_production__employee_container {
	border: 1px solid $primary-dark-gray;
	max-height: 530px;
}


.employee_production__form_title {
	font-family: "GintoNormal-Black";
	font-size: 18px;
	text-transform: uppercase;

}

.employee_production__dentist_form_container {
	height: 217px;
	margin-top: 12px;
	overflow-y: scroll;
	border-bottom: 1px solid $primary-dark-gray;
}

.employee_production__hygienist_form_container {
	height: 287.5px;
	margin-top: 12px;
	overflow-y: scroll;
	border-bottom: 1px solid $primary-dark-gray;
}

.employee_production__form_button {
	@include rounded-button;
	background-color: transparent;
	margin-left: 60px;
	height: 48px;
	width: 160px;
	position: absolute;
	left: 255px;
    top: -78px;
}

.employee_production__form_button:hover {
	@include rounded-button;
	background-color: $primary-dark-gray;
	color: $primary-pink;
	height: 48px;
	width: 160px;
}

.employee_production__loading_animation {
	height: 100px;
	width: 100px;
	position: absolute;
	top: 25%;
	left: 40%;
	z-index: 1000;
}
