$primary-button-height: 60px;

@mixin rounded-button {
	border: 1px solid $primary-dark-gray;
	border-radius: 30px;
	height: $primary-button-height;
	width: 240px;
	font-family: 'GintoNormal-Black';
	font-size: 14px;
	text-transform: uppercase;
}