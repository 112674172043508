.login__main_container {
	height: 100%;
	background-color: $primary-light-gray;
}

.login___container {
	padding-top: 80px;
}

.login___header_container {
	height: 160px;
}

.login___logo {
	width: 240px;
	position: absolute;
	top: 40px;
	left: calc(50% - 122px);
}

.login___form_container {
	background-color: transparent;
	padding-top: 48px;
	padding-bottom: 48px;
	min-height: 640px;
}

.login__header {
	font-family: 'DomaineDisplay-Regular';
    font-size: 36px;
	letter-spacing: -0.6px;
	line-height: 64px;
    letter-spacing: 0px;
    margin-top: 74px;
	margin-bottom: 28px;
	width: 286px;
	position: absolute;
	left: calc(50% - 146px);
}

.login__header_bolded {
	font-family: 'GintoNormal-Black';
	font-size: 36px;
	letter-spacing: -1.8px;
	line-height: 64px;
	margin-bottom: 20px;
}

.login__text_field_header {
	font-family: 'GintoNormal-Black';
	font-size: 16px;
	padding-top: 12px;
	margin-bottom: 8px;
}

.login__email_text_field_header {
	font-family: 'GintoNormal-Light';
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.55px;
	padding-top: 0px;
	padding-bottom: 8px;
	margin-bottom: 0px;
}

.login__email_textfield_container {
	
}

.login__email_text_field_input {
	background-color: $primary-medium-gray;
	border: none;
	border-bottom: 1px solid $primary-dark-gray;
	padding: 2px 10px;
	font-family: 'DomaineText-Light';
	font-size: 16px;	
	width: 100%;
	max-width: 100%;
}

.login__email_error_container {
	color: $primary-blue;
	min-height: 30px;
	padding-left: 10px;
}

.login__password_text_field_header {
	font-family: 'GintoNormal-Light';
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.55px;
	padding-top: 0px;
	padding-bottom: 8px;
	margin-bottom: 0px;
}

.login__password_textfield_container {
	
}

.login__password_text_field_input {
	background-color: $primary-medium-gray;
	border: none;
	border-bottom: 1px solid $primary-dark-gray;
	padding: 2px 10px;
	font-family: 'DomaineText-Light';
	font-size: 16px;	
	width: 100%;
	max-width: 100%;
}

.login__password_error_container {
	color: $primary-blue;
	min-height: 30px;
	padding-left: 10px;
}

.login__button_container {
	text-align: center;
}

.login__button {
	@include rounded-button;
	background-color: transparent;
	height: 48px;
	width: 160px;
	margin-top: 10px;
}

.login__button:hover {
	@include rounded-button;
	background-color: $primary-dark-gray;
	color: $primary-pink;
	height: 48px;
	width: 160px;
}
