.employee_search_user__container {
	height: 59px;
	cursor: pointer;
}


.employee_search_user__main_container:first-of-type {
	border-bottom: none;
}


.employee_search_user__main_container {
	border-bottom: 1px solid $primary-medium-gray;
}

.employee_search_user__image_container {
	height: 45px;
	width: 45px;
}

.employee_search_user__image {
	width: 100%;
	max-height: 60px;
}

.employee_search_user__name {
	font-family: "GintoNormal-Medium";
	font-size: 16px;
	margin-bottom: 0px;
}

.employee_search_user__preferred_name {
	font-size: 12px;
	margin-bottom: 0px;
}

.employee_search_user__ehr_id {
	font-family: "GintoNormal-Medium";
	font-size: 16px;
	margin-bottom: 0px;
}

.employee_search_user__app_id {
	font-size: 12px;	
	margin-bottom: 0px;
}

.employee_search_user__date_of_birth {
	font-family: "GintoNormal-Medium";
	font-size: 16px;
}

.employee_search_user__practice {
	font-family: "GintoNormal-Medium";
	font-size: 16px;
}