.message___user {
	float: left;
	background-color: $primary-pink;
	margin-left: 24px;
}

.message___employee {
	float: right;
	background-color: $primary-light-gray;
	margin-right: 24px;
}

.message___main_container {
	position: relative;
	padding: 12px 24px;
	margin-bottom: 36px;
	border-radius: 6px;
	font-size: 12px;
	max-width: 360px;
}

.message___image {
	max-height: 240px;
	max-width: 100%;
}

.message___text {
	margin-bottom: 0px;
}

.message__timestamp {
	font-family: "GintoNormal-Light";
	font-size: 10px;
	position: absolute;
	bottom: 20px;
}

.message__timestamp_employee {
	right: 44px;
}

.message__timestamp_user {
	left: 44px;
}