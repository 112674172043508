.appointment_card__main_container {
}

.appointment_card__container {
	background-color: $primary-light-gray;
	border: 1px solid $primary-dark-gray;
    border-radius: 16px;
    max-width: 328px;
    margin-bottom: 16px;
}

.appointment_card__title {
	color: $primary-dark-gray;
	font-family: 'DomaineDisplay-Regular';
	font-size: 24px;
	text-align: center;
	padding-top: 24px;
	margin-bottom: 4px;
}

.appointment_card__duration_label {
	color: $primary-dark-gray;
	font-family: 'DomaineDisplay-Regular';
	font-size: 16px;
}

.appointment_card__time_label {
	color: $primary-dark-gray;
	font-family: 'GintoNormal-Medium';
	font-size: 14px;
	text-align: center;
	padding-bottom: 24px;
	margin-bottom: 0px;
}
