.smile_cycle_checkout__main_container {
	background-color: $primary-light-gray;
	padding-bottom: 200px;
}

.smile_cycle_checkout__nav_container {
	background-color: $primary-light-gray;
}

.smile_cycle_checkout__header_main_container {
	position: relative;
}

.smile_cycle_checkout__header_smiley_sticky {
	position: absolute;
    top: 74px;
    right: 142px;
    transform: rotate(45deg);
}

.smile_cycle_checkout__header_smiley_more_sticky {
	position: absolute;
    top: 284px;
    right: 610px;
}

.smile_cycle_checkout__header_tooth_icon {
	position: absolute;
    top: 530px;
    right: 278px;
}

.smile_cycle_checkout__header_text {
	font-family: 'DomaineDisplay-Regular';
	font-size: 72px;
	height: 624px;
	max-width: 624px;
	margin-top: 40px;
	line-height: 72px;
}

.smile_cycle_checkout__bolded_header {
	font-family: 'GintoNormal-Black';
	font-size: 28px;
}


.smile_cycle_checkout__header_row_container {
	border-top: 1px solid $primary-dark-gray;
	background-color: $primary-light-gray;
	padding-top: 36px;
	text-align: center;
}

.smile_cycle_checkout__image {
	margin-top: 20px;
	max-width: 300px;
}

.smile_cycle_checkout__image_container {
	text-align: center;
}

.smile_cycle_checkout__description_text {
	max-width: 560px;
	text-align: center;
	margin: 0 auto;
	padding-top: 20px;
	padding-bottom: 60px;
}

.smile_cycle_checkout__prompt_text {
		font-family: 'DomaineDisplay-Regular';
	font-size: 32px;
	text-align: center;
	margin-top: 40px;
	margin-bottom: 40px;
}

.smile_cycle_checkout__prompt_text_bold {
	font-family: 'GintoNormal-Black';
	font-size: 32px;
	text-align: center;
	border-bottom: 1px solid $primary-dark-gray;
}

.smile_cycle_checkout__affirm_container {
	position: relative;
}

.smile_cycle_checkout__affirm_logo {
    display: inline-block;
    position: absolute;
    top: -24px;
    right: 176px;
    height: 90px;
    width: 90px;
}

.smile_cycle_checkout__pay_text {
		font-family: 'DomaineDisplay-Regular';
	font-size: 28px;
	text-align: center;
	padding-top: 16px;
} 

.smile_cycle_checkout__pay_line_1 {
	font-size: 20px;
	text-align: center;
	margin-bottom: 24px;
}

.smile_cycle_checkout__pay_line_2 {
	font-family: 'GintoNormal-Black';
	font-size: 20px;
	text-align: center;
	margin-bottom: 24px;
}


.smile_cycle_checkout__pay_line_3_more_expensive {
	color: $primary-orange;
	font-size: 20px;
	text-align: center;
	margin-bottom: 24px;
}

.smile_cycle_checkout__pay_line_3_cheaper {
	color: $primary-blue;
	font-size: 20px;
	text-align: center;
	margin-bottom: 24px;
}

.smile_cycle_checkout__pay_line_4 {
	border-top: 1px solid $primary-dark-gray;
	padding-top: 14px;
	margin-left: 48px;
	margin-right: 48px;
	font-family: 'GintoNormal-Black';
	font-size: 24px;
	text-align: center;
}

.smile_cycle_checkout__button_container {
	text-align: center;
	margin-top: 48px;
}

.smile_cycle_checkout__pay_button_affirm {
	@include rounded-button;
	background-color: transparent;
	height: 48px;
	width: 160px;
}

.smile_cycle_checkout__pay_button_up_front {
	@include rounded-button;
	background-color: transparent;
	height: 48px;
	width: 160px;
}

.smile_cycle_checkout__pay_button_affirm:hover {
	@include rounded-button;
	background-color: $primary-pink;
	height: 48px;
	width: 160px;
}

.smile_cycle_checkout__pay_button_up_front:hover {
	@include rounded-button;
	background-color: $primary-pink;
	height: 48px;
	width: 160px;
}