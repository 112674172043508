.repair_issue_card__main_container {
  // border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 12px 0px;
  margin-right: 20px;
}

.repair_issue_card__title {
  font-family: "GintoNormal-Medium";
}

.repair_issue_card__name {
  font-size: 16px;
}

.repair_issue_card__type {
  font-size: 16px;
}

.repair_issue_card__practice {
  font-size: 16px;
}

.repair_issue_card__id {
  font-size: 16px;
}

.repair_issue_card__location {
  font-size: 16px;
}

.repair_issue_card__description {
  font-size: 16px;
}

.repair_issue_card__date {
  font-size: 16px;
}

.repair_issue_card__last_editor {
  margin-top: 14px;
  font-size: 16px; 
}

.repair_issue_card__practice_form_select {
  margin-top: 0px;
  height: 28px;
  min-width: 120px;
}

.repair_issue_card__form_practice_update_button {
  // height: 20px;
  // width: 20px;
  cursor: pointer;
  // padding-bottom: 4px;
  height: 28px;
  width: 28px;
  margin-left: 10px;
  padding: 0px;
  // top: -8px;
}

.repair_issue_card__practice_form_select {

}

.repair_issue_card__form_practice_update_button_icon {
  height: 14px;
  width: 14px;
  position: relative;
  top: -2px;
}