.chat__container {
	height: calc(100% - 58px);
}

.chat__main_container {
	height: 100%;
	padding-left: 0px;
}

.chat__message_input_field {
	background-color: $primary-medium-gray;
	border: none;
	height: 100%;
	height:100vh;
	max-height: 120px;
	padding: 12px 24px 12px 24px;
    margin-left: 426px;
    width: calc(100% - 570px);
}

.chat__message_input_field_container {
	background-color: $primary-light-gray;
	margin-left: -436px;
}

.chat__messages_container {
	height: calc(100% - 166px);
	background-color: white;
	overflow-x: hidden;
	overflow-y: scroll;
}

.chat__messages_header {
	font-family: "DomaineText-Medium";
	font-size: 16px;
	text-align: left;
	padding-left: 24px;
	margin-top: 12px;
	margin-bottom: 0px;
}

.chat__messages_subheader {
	font-family: "DomaineText-Light";
	font-size: 16px;
}

.chat__load_old_messages_link_container {
	text-align: center;
	margin-bottom: 10px;
}

.chat__load_old_messages_link {
    cursor: pointer;
    text-decoration: underline !important;
    font-size: 14px;
}

.chat__load_old_messages_link:hover {
    cursor: pointer;
    color: $primary-orange !important;
    text-decoration: underline !important;
    font-size: 14px;
}

.chat__messages_date_header {
	font-family: 'GintoNormal-Medium';
	color: $primary-dark-gray;
	font-size: 12px;
	text-align: center;
}

.chat__message_input_field_col_container {
	background-color: $primary-medium-gray;
	border-top: 1px solid $primary-dark-gray;
}

.chat__message_input_field_button {
	background-color: $primary-medium-gray;
	border: 1px solid $primary-dark-gray;
	box-shadow: none;
	border-radius: 15px;
	font-size: 14px; 
	font-family: 'DomaineText-Medium';
	font-weight: 600;
	height: 32px;
	width: 120px;
	letter-spacing: 0.5px;
	margin: 12px 0px 0px 12px;
	outline: none !important;
    outline-offset: none !important;
	position: absolute;
	top: 0px;
}

.chat__message_input_field_button:hover {
	background-color: $primary-dark-gray;
	border: 1px solid $primary-dark-gray;
	color: $primary-pink;
	box-shadow: none;
	border-radius: 15px;
	font-size: 14px; 
	font-family: 'DomaineText-Medium';
	font-weight: 600;
	height: 32px;
	width: 120px;
	letter-spacing: 0.5px;
	margin: 12px 0px 0px 12px;
	outline: none !important;
    outline-offset: none !important;
	position: absolute;
	top: 0px;
}


@media (max-width: 768px) {
	.chat__messages_header {
		font-family: "DomaineText-Medium";
		font-size: 16px;
		text-align: center;
		padding-left: 24px;
		margin-top: 6px;
		margin-bottom: 12px;
	}
}