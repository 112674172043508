.insurance_policy_card__main_container {
}

.insurance_policy_card__container {
    border-bottom: 1px solid $primary-dark-gray;
    padding-bottom: 6px;
    margin-bottom: 6px;
}

.insurance_policy_card__title {
	color: $primary-dark-gray;
	font-family: 'DomaineDisplay-Regular';
	font-size: 24px;
	text-align: center;
	padding-top: 24px;
	margin-bottom: 4px;
}

.insurance_policy_card__info {
	font-size: 14px;
	margin-bottom: 4px;
}

.insurance_policy_card__info_label {
	font-family: 'GintoNormal-Medium';
	font-size: 14px;
	margin-bottom: 4px;
}
