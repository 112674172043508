.conversation_card__container {
	border-bottom: 1px solid lightgray;
	height: 34px;
}

.conversation_card__row_container {
	background-color: white;
	border-bottom: 1px solid lightgray;
	height: 34px;
}

.conversation_card__row_container:hover {
	background-color: $primary-light-gray;
	border-bottom: 1px solid lightgray;
	height: 34px;
}

.conversation_card__last_message_status_has_unread {
    height: 12px;
    width: 12px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
    margin-left: 12px;
    position: absolute;
    top: 10px;
}

.conversation_card__last_message_status_has_unread_label {
	font-family: "GintoNormal-Medium";
	font-size: 14px;
	position: absolute;
	left: 52px;
	top: 5px;
}

.conversation_card__status_container {
	height: 33px;
	position: relative;
}

.conversation_card__last_message_status {
	height: 12px;
	width: 12px;
	background-color: gray;
	border-radius: 50%;
	display: inline-block;
	margin-left: 12px;
	position: absolute;
	top: 10px;
}

.conversation_card__last_message_status_label {
	font-family: "GintoNormal-Medium";
	margin-left: 12px;
	font-size: 14px;
}

.conversation_card__user_name_label {
    font-size: 14px;
    position: absolute;
    top: 4px;
    height: 28px;
    text-overflow: ellipsis;
    width: 100%;
}

.conversation_card__last_message_body_label {
	font-size: 14px;
	 top: 4px;
    position: absolute;
	overflow-y: hidden;
	max-height: 20px;
}

.conversation_card__last_message_created_at_label {
	font-size: 14px;
    position: absolute;
    top: 4px;
}

@media (max-width: 575.98px) {
	.conversation_card__last_message_status {
		margin-left: 24px;
	}
}