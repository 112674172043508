@import './mixins.scss';
@import './colors.scss';
@import './components/Home';
@import './components/Login';
@import './components/NavMenu';
@import './components/PatientProfile';
@import './components/Inbox';
@import './components/ConversationCard';
@import './components/Chat';
@import './components/Message';
@import './components/AppointmentCard';
@import './components/EmployeeSearchUserCard';
@import './components/NoSsnUserCard';
@import './components/SmileCycleCheckout';
@import './components/InsurancePolicyCard';
@import './components/MedicalSurveyCollectionCard';
@import './components/MedicalSurveyQuestionCard';
@import './components/Production';
@import './components/ScheduleGenerator';
@import './components/EmployeeProduction';
@import './components/PracticeProduction';
@import './components/HorizontalBarChartProduction';
@import './components/RepairIssuesList';
@import './components/RepairIssueCard';
@import './components/App';



html {
	margin: 0;
	height: 100%;
}

body {
	margin: 0;
	height: 100%;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {margin: 0; height: 100%; overflow: hidden}


#root {margin: 0; height: 100%; overflow: hidden}

.app_root {margin: 0; height: 100%; overflow: hidden}

code {
 	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
 	font-family: 'GintoNormal-Light';
 	src: local('GintoNormal-Light'), url('../resources/fonts/GintoNormal-Light.ttf') format('truetype');
}

@font-face {
 	font-family: 'GintoNormal-Medium';
 	src: local('GintoNormal-Medium'), url('../resources/fonts/GintoNormal-Medium.woff') format('woff');
}

@font-face {
 	font-family: 'GintoNormal-Black';
 	src: local('GintoNormal-Black'), url('../resources/fonts/GintoNormal-Black.woff') format('woff');
}

@font-face {
 	font-family: 'DomaineDisplay-Regular';
 	src: local('DomaineDisplay-Regular'), url('../resources/fonts/DomaineDisplay-Regular.ttf') format('truetype');
}

@font-face {
 	font-family: 'DomaineText-Medium';
 	src: local('DomaineText-Medium'), url('../resources/fonts/DomaineText-Medium.ttf') format('truetype');
}

body {
	font-family: 'GintoNormal-Light';
}
