.nav_menu___container {
	background-color: $primary-light-gray;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
	height: 100%;
	position: fixed;
	padding: 0px 24px;
	z-index: 1;
	left: 0;
	max-width: 216px;
}

.nav_menu__header {
	font-family: "DomaineDisplay-Regular";
}

.nav_menu__link {
	cursor: pointer;
	font-family: "GintoNormal-Medium";
	padding-left: 10px;
}

.nav_menu__link:hover {
	text-decoration: underline;
}

.nav_menu__title_header {
	cursor: pointer;
	font-family: DomaineDisplay-Regular;
	font-size: 28px;
	margin: 24px 0px 24px 24px;
}

.nav_menu__logo_image {
	cursor: pointer;
	width: 100%;
	padding: 24px 10px;
}

.nav_menu__new_message {
	font-family: 'GintoNormal-Black';
	font-size: 14px;
}