.production__tab_link {
	color: gray;
	cursor: pointer;
	font-size: 18px;
	font-family: "GintoNormal-Medium";
	padding-top: 24px;
}

.production__tab_link:hover {
	color: $primary-dark-gray;
	cursor: pointer;
	font-size: 18px;
	font-family: "GintoNormal-Medium";
	text-decoration: underline;
}

.production__tab_link_selected {
	color: $primary-dark-gray;
	text-decoration: underline;
}

.production__date_picker {
}