textarea:focus, input:focus{
    outline: none;
}

.home__main_container {
	height: 100%;
	overflow-x: hidden;
}

.home__search_bar {
	border: 1px solid $primary-dark-gray;
	padding: 4px 12px;
	border-radius: 24px;
    margin: 12px 0px;
	width: 100%;
}

.home__search_container {
	border-left: 1px solid $primary-dark-gray;
	border-right: 1px solid $primary-dark-gray;
	border-bottom: 1px solid $primary-dark-gray;
	background-color: white;
	position: absolute;
	top: 45px;
	left: 28px;
	max-height: 440px;
	overflow-y: scroll;
	width: 100%;
	width: calc(100% - 80px);
	z-index: 1;
}

.home__search_container {
	display: block;
	background-color: $primary-light-gray;
}

.home__nav_container {

}

@media (max-width: 575.98px) { 
	.home__search_bar {
		// width: 100%;
		width: calc(100% - 48px);
		margin: 12px 0px 12px 24px;
	}
}