.inbox__main_container {
	border: 1px solid lightgray;
	border-left: 0px;
	height: calc(100vh - 117px);
	padding-left: 15px;
	padding-right: 0px;
	overflow-y: scroll;
	overflow-x: hidden;
	cursor: pointer;
}

.inbox__pagination_container {
	width: 100%;
	font-size: 14px;
	text-align: center;
	position: absolute;
	padding-top: 14px;
}

.inbox__pagination_right_arrow {
    height: 100%;
    max-height: 12px;
    top: 6.5px;
    cursor: pointer;
}

.inbox__pagination_left_arrow {
    height: 100%;
    max-height: 12px;
    top: 6.5px;
    cursor: pointer;
}

.inbox__pagination_left_arrow_container {
	max-width: 10px;
	display: inline-block;
}

.inbox__pagination_right_arrow_container {
	max-width: 10px;
	display: inline-block;
}

.inbox__pagination__text_container {
	display: inline-block;
	padding-left: 12px;
	padding-right: 12px;
}

.inbox__pagination_container_text {
	margin-bottom: 0px;
}

.inbox__loading_animation {
	height: 100px;
	width: 100px;
	position: absolute;
	top: 25%;
	left: 40%;
	z-index: 1000;
}