.medical_survey_collection_card__container {
	height: 152px;
	overflow-y: scroll;
	overflow-x: hidden;
	border-bottom: 1px solid $primary-dark-gray;
}

.medical_survey_collection_card__title {
	font-size: 16px;
	margin-bottom: 4px;
	margin-top: -4px;
}

.medical_survey_collection_card__info_label {
	font-family: 'GintoNormal-Black';
	font-size: 14px;
}

.medical_survey_collection_card__status {
	margin-bottom: 2px;
	font-size: 14px;
}

.medical_survey_collection_card__status_label_completed {
	color: green;
	font-size: 14px;
	text-transform: uppercase;
}

.medical_survey_collection_card__status_label_incomplete {
	color: $primary-orange;
	font-size: 14px;
	text-transform: uppercase;
}

.medical_survey_collection_card__date_completed {
	font-size: 14px;
	margin-bottom: 10px;
}