.practice_production__main_container {
	margin-top: 14px;
}

.practice_production__practice_container {
	border: 1px solid $primary-dark-gray;
	height: 284px;
}


.practice_production__form_title {
	font-family: "GintoNormal-Black";
	font-size: 18px;
	text-transform: uppercase;

}

.practice_production__practice_form_container {
	margin-top: 12px;
	overflow-y: scroll;
}

.practice_production__hygienist_form_container {
	height: 287.5px;
	margin-top: 12px;
	overflow-y: scroll;
}

.practice_production__form_button {
	@include rounded-button;
	background-color: transparent;
	margin-left: 60px;
	height: 48px;
	width: 160px;
	position: absolute;
	left: 255px;
    top: -78px;
}

.practice_production__form_button:hover {
	@include rounded-button;
	background-color: $primary-dark-gray;
	color: $primary-pink;
	height: 48px;
	width: 160px;
}


